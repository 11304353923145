$theme-colors: (
  "primary": #073342,
  "green": #8bc53f,
);

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";

/* Importing Custom Bootstrap CSS file */
@import "styles/custom-style";
@import "styles/custom-responsive";

/* Importing Application files */
@import "./styles/variables";
@import "./styles/animations";
@import "./styles/global";
