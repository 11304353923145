@import "./variables";

:root {
  --CFEP-Bright-Red-color: #c91b1b;
  --CFEP-Bright-Yellow-color: #ece921;
  --CFEP-Bright-Green-color: #49e963;
}

.btn-naked {
  padding: 0;
  font-size: 1em;
  color: $primary;
}

strong {
  font-family: "frank_newbold";
}

@mixin svgSize($size) {
  @if $size > 24 {
    width: #{$size}px;
    height: #{$size}px;
  } @else {
    transform: scale(calc($size / 24));
  }
}

// default is 24
$svgSizes: 12, 16, 18, 20, 22, 24, 32;

@each $svgSize in $svgSizes {
  mat-icon.mat-icon-size-#{$svgSize} {
    @include svgSize($svgSize);
  }
}

.toaster-fixed {
  position: fixed;
  top: 12px;
  right: 12px;
}

.overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.warning-text,
.delete-icon {
  color: var(--CFEP-Red-color) !important;
  font-weight: 600;
}
.warning-red-text {
  color: red;
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

button:disabled {
  pointer-events: none;
  background-color: grey !important;
}

.form-error {
  color: red;
}

.required-asterisk {
  color: red;
  font-size: 1.2rem;
}

.full-tab ul li.nav-item {
  width: 100%;
  cursor: pointer;
}

.sideBar .nav-link {
  padding: 0;
}

// Override material design components so they don't look so massseeeeve

mat-option {
  font-size: 0.875rem;
  min-height: 0px !important;
}

mat-form-field {
  width: 100%;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius-sm);

  input {
    font-size: 0.875rem;
  }
}

::ng-deep {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--bs-body-bg);
  }
  .mat-mdc-form-field-infix {
    min-height: 20px !important;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 2px !important;
    padding-bottom: 4px !important;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
  }
}

.survey-page-content {
  .refresh-btn button {
    padding: 0 5px;
  }
}

.running-icon {
  color: $orange;
}

.exception-icon,
.cancelled-icon {
  color: $red;
}

.scheduled-icon {
  color: $blue;
}

.completed-icon {
  color: $green;
}

.remove-icon {
  color: $red;
  cursor: pointer;
}

legend.scheduler-border.full-width {
  max-width: none;
  width: 100%;
}
