body {
  font-family: "frank_newregular";
  color: var(--CFEP-blue-color);
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  margin: 0;
}
h1,
h2,
h3 {
  font-family: "frank_newbold";
}
/*--------------------------------------------------------------
## Custom font family
--------------------------------------------------------------*/
@font-face {
  font-family: "frank_newbold";
  src:
    url("/assets/fonts/franknew-bold-webfont.woff2") format("woff2"),
    url("/assets/fonts/franknew-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "frank_newlight";
  src:
    url("/assets/fonts/franknew-light-webfont.woff2") format("woff2"),
    url("/assets/fonts/franknew-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "frank_newregular";
  src:
    url("/assets/fonts/franknew-regular-webfont.woff2") format("woff2"),
    url("/assets/fonts/franknew-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*--------------------------------------------------------------
## Dashboard colors
--------------------------------------------------------------*/
:root {
  --CFEP-slate-color: #073342;
  --CFEP-Navy-color: #1d6398;
  --CFEP-blue-color: #35556d;
  --CFEP-Cyan-color: #26a9e0;
  --CFEP-Green-color: #8bc53f;
  --CFEP-Lime-color: #cedc2a;
  --CFEP-Stone-color: #f8f8f8;
  --CFEP-Blue-color: #35556d;
  --CFEP-Leaf-color: #2bb673;
  --CFEP-Grey-color: #c3d4ca;
  --CFEP-Purple-color: #a77b8d;
  --CFEP-Red-color: #b25538;
  --CFEP-Orange-color: #e68a1b;

  --white-color: #ffffff;
  --black-color: #000;
}
.white-txt {
  color: var(--white-color);
}
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
  color: var(--pink-color);
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus,
a:active {
  color: var(--black-color);
  text-decoration: none;
}

/* a:focus {
  outline: none;
}

a:hover,
a:active {
  outline: 0;
} */
/*--------------------------------------------------------------
## Sites buttons
--------------------------------------------------------------*/
button.btn-adv {
  background-color: #f3f3f3;
  border: 1px solid #e1e1e1;
  padding: 8px 10px;
  font-size: 16px;
  color: var(--CFEP-blue-color);
}
button.btn-adv:hover {
  border-color: var(--CFEP-blue-color);
}

button.btn-search {
  background-color: var(--CFEP-blue-color);
  border-color: var(--CFEP-blue-color);
  padding: 8px 10px;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  color: var(--white-color);
}
button.btn-search:hover {
  background-color: var(--CFEP-blue-color);
  border-color: var(--CFEP-blue-color);
}
button.btn-search img {
  width: 16px;
}
button.btn-clear {
  background-color: var(--CFEP-Grey-color);
  border-color: #ccc;
  border: none;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 14px;
  color: var(--CFEP-blue-color);
}
button.btn-clear:hover {
  background-color: #ddd;
  border-color: #ddd;
}
button.btn-deal {
  background-color: var(--CFEP-blue-color);
  border-color: var(--CFEP-blue-color);
  border: none;
  border-radius: 3px;
  padding: 10px 18px;
  font-size: 14px;
  color: var(--white-color);
}
button.btn-deal:hover {
  background-color: var(--CFEP-slate-color);
  border-color: var(--CFEP-blue-color);
  box-shadow: none;
}
.survey-star .btn-startSurvey {
  margin-top: 80px;
}
.btn-startSurvey {
  background-color: var(--CFEP-Green-color);
  border-color: var(--CFEP-Green-color);
  border: none;
  border-radius: 3px;
  padding: 10px 18px;
  font-size: 14px;
  color: var(--white-color);
}
.btn-startSurvey:hover {
  background-color: #81ad38;
  border-color: #81ad38;
  box-shadow: none;
  color: var(--white-color);
}
button.btn-deal img {
  position: relative;
  top: -5px;
}
button.btn-green {
  background-color: var(--CFEP-blue-color);
  border-color: var(--CFEP-blue-color);
  border: none;
  border-radius: 3px;
  padding: 10px 18px;
  font-size: 18px;
  width: 100%;
  text-transform: uppercase;
  color: var(--white-color);
}
button.btn-green:hover {
  background-color: var(--CFEP-blue-color);
  border-color: var(--CFEP-blue-color);
  color: var(--white-color);
}
/* div within td */
.txt-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}
/*==========  Thin wrap ========= */
.thin-btn-wrap {
  display: flex;
  gap: 10px;
}
.thin-btn-wrap .gray-btn {
  background: #5dc8f5;
  padding: 5px 18px;
  color: #fff;
  border-radius: 3px;
  border: none;
  font-size: 11px;
  height: 24px;
  line-height: 15px;
}
.thin-btn-wrap .gray-btn:hover {
  background: #54bde9;
}
.thin-btn-wrap .blue-btn {
  background: #35556d;
  padding: 5px 12px;
  color: #fff;
  border-radius: 3px;
  border: none;
  font-size: 11px;
  height: 24px;
  line-height: 15px;
}
.thin-btn-wrap .blue-btn:hover {
  background: #213b4f;
}
/*==========  big btn wrap ========= */
.big-btn-wrap {
  display: flex;
  gap: 10px;
}
.big-btn-wrap .gray-btn {
  background: #5dc8f5;
  padding: 5px 18px;
  color: #fff;
  border-radius: 3px;
  border: none;
  font-size: 14px;
}
.big-btn-wrap .gray-btn:hover {
  background: #54bde9;
}
.big-btn-wrap .blue-btn {
  background: #35556d;
  padding: 5px 18px;
  color: #fff;
  border-radius: 3px;
  border: none;
  font-size: 14px;
}
.big-btn-wrap .blue-btn:hover {
  background: #213b4f;
}
.small-btn-wrap .blue-btn {
  background: #35556d;
  padding: 5px 12px;
  color: #fff;
  border-radius: 3px;
  border: none;
  font-size: 13px;
}
.small-btn-wrap .blue-btn:hover {
  background: #213b4f;
}
.small-btn-wrap .gray-btn {
  background: #5dc8f5;
  padding: 5px 12px;
  color: #fff;
  border-radius: 3px;
  border: none;
  font-size: 13px;
}
.small-btn-wrap .gray-btn:hover {
  background: #54bde9;
}
.doc-btn {
  background: #e5e5e5;
  padding: 8px;
  display: flex;
  gap: 15px;
}
.doc-btn button {
  background: #5dc8f5;
  padding: 5px 20px;
  color: #fff;
  border-radius: 3px;
  border: none;
}
.doc-btn button:hover {
  background: #51b6e1;
}
.btn-wrap {
  display: flex;
  gap: 15px;
}
.btn-wrap .cancel-btn {
  background: #5dc8f5;
  padding: 8px 20px;
  color: #fff;
  border-radius: 3px;
  border: none;
}
.btn-wrap .cancel-btn:hover {
  background: #51b6e1;
}
.btn-wrap .save-btn {
  background: #35556d;
  padding: 8px 20px;
  color: #fff;
  border-radius: 3px;
  border: none;
}
.btn-wrap .save-btn:hover {
  background: #213b4f;
}
.regular-btn-wrap {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.regular-btn-wrap .blue-btn {
  background: #5dc8f5;
  padding: 8px 10px;
  border-radius: 3px;
  border: none;
  color: #fff;
}
.regular-btn-wrap .blue-btn:hover {
  background: #51b6e1;
}
.light-md-btn {
  background: #b8b8b8;
  padding: 6px 20px;
  color: #fff;
  border-radius: 3px;
  border: none;
  line-height: 24px;
}
.light-md-btn:hover {
  background: #909090;
  color: #fff;
}

/*--------------------------------------------------------------
## Page wraper style start
--------------------------------------------------------------*/
#dash-wrap {
  display: flex;
  margin: 0 20px;
}
.sideBar {
  position: fixed;
  width: 98px;
  background: var(--CFEP-Stone-color);
  height: 100vh;
}
.content-wrap {
  height: 100vh;
  width: 100%;
  margin-left: 122px;
}
/* Side nav on big screen style */
ul.nav .nav-item {
  padding: 10px 0;
}
ul.nav .nav-item a span {
  display: block;
  font-size: 11px;
  line-height: 13px;
  margin-top: 5px;
}
ul.nav .nav-item a {
  color: var(--CFEP-blue-color);
}
ul.nav .nav-item a:hover {
  color: var(--CFEP-blue-color);
}
/* Responsive Nav */
.nav-phn {
  display: none;
}
.nav-phn img.phn-toggle {
  width: 26px;
  cursor: pointer;
}
/* =============== Top header screen style =========== */
header {
  background-color: var(--white-color);
  padding: 10px;
}
header img.cfep-logo {
  max-width: 160px;
}
.welcome-name p {
  font-size: 12px;
  font-family: "frank_newlight";
  margin-bottom: 0;
}
.welcome-name p span {
  font-family: "frank_newbold";
  color: var(--CFEP-blue-color);
}
.logout {
  position: relative;
}
.logout::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 16px;
  background: var(--CFEP-Blue-color);
  left: -7px;
  top: 4px;
}
.logout a {
  font-size: 13px;
  font-family: "frank_newregular";
}
.refresh-wrap .text-side {
  background: var(--CFEP-Stone-color);
  padding: 10px;
}
.refresh-wrap .text-side span {
  font-size: 13px;
  color: var(--CFEP-Blue-color);
}
.refresh-wrap .refresh-btn button {
  background: var(--CFEP-blue-color);
  border: none;
  padding: 10px;
}
.refresh-wrap .refresh-btn button i {
  color: var(--white-color);
  font-size: 16px;
}

/* =============== Page content div style =========== */
h2.title {
  font-size: 22px;
  color: var(--CFEP-blue-color);
}
h3.title {
  font-size: 18px;
  color: var(--CFEP-blue-color);
}
h5.title {
  font-size: 14px;
  color: var(--CFEP-blue-color);
  font-weight: bold;
}
.page-content {
  padding: 20px;
  overflow: hidden;
  background: var(--CFEP-Stone-color);
}
.page-content ul.nav {
  border-bottom: 1px solid;
  border-color: var(--CFEP-Blue-color);
}
.page-content ul.nav .nav-item {
  padding: 0;
}
.page-content ul.nav .nav-item a {
  font-size: 14px;
  position: relative;
  top: -1px;
  color: var(--CFEP-blue-color);
}
.page-content ul.nav .nav-item a.active {
  background-color: var(--CFEP-Blue-color);
  color: var(--white-color);
  position: relative;
  top: 1px;
}
.page-content .in-progress {
  margin-top: 20px;
}
.in-progress .box-1 {
  /* background: var(--CFEP-blue-color); */
  background: #073342;
}
.in-progress .box-2 {
  /* background: var(--CFEP-Navy-color); */
  background: #1d6398;
}
.in-progress .box-3 {
  /* background: var(--CFEP-Cyan-color); */
  background: #26a9e0;
}
.in-progress .box-4 {
  /* background: var(--CFEP-blue-color); */
  background: #8bc53f;
}
.in-progress .box-5 {
  /* background: var(--CFEP-Lime-color); */
  background: #cedc2a;
}
.in-progress .comman-box {
  padding: 50px 0;
  text-align: center;
  position: relative;
  color: var(--white-color);
}
.in-progress .comman-box span {
  font-size: 14px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 13px;
}
/* ============= Advance survey search div style ========== */
.adv-wrap {
  width: 100%;
  padding: 15px;
  border: 1px solid;
  border-color: var(--CFEP-Grey-color);
}
button.btn-adv {
  position: relative;
}
:not(.collapsed) ::before {
  transform: rotate(-180deg);
}
/* ============= Sorting tabs div style ========== */
.sorting-tabs {
  margin-top: 30px;
}
.sorting-tabs ul.nav {
  border-bottom: 0px !important;
}
.sorting-tabs ul li.nav-item {
  width: 50%;
  background: #e3e3e3;
  padding: 5px !important;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.sorting-tabs .nav-tabs .nav-item.show .nav-link,
.sorting-tabs .nav-tabs .nav-link.active {
  background: var(--CFEP-Blue-color);
  border-radius: 0;
  color: var(--white-color);
}
.sorting-tabs ul li.nav-item span {
  font-size: 18px;
  color: var(--CFEP-Blue-color);
}
.sorting-tabs ul li.nav-item span:hover {
  border: none;
}
.sorting-tabs .input-group {
  width: auto;
}
.sorting-tabs .input-group input {
  border-radius: 0;
  font-size: 13px;
}
.sorting-tabs-data .table thead tr th {
  background-color: var(--CFEP-Grey-color) !important;
  font-size: 14px;
  color: var(--CFEP-blue-color);
  font-weight: 500;
  white-space: nowrap;
}
.sorting-tabs-data .table tbody tr td {
  font-size: 14px;
  color: var(--CFEP-blue-color);
}
/* ============= Login register forgot screen style ========== */
.bg-wrap {
  background: url(/assets/images/CFEP-Background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -236px;
  height: 100vh;
}
header.login-hdr {
  border-top: 10px solid;
  border-color: var(--CFEP-blue-color);
  text-align: center;
}
.welcome-wrap {
  max-width: 925px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 15px auto;
}
.welcome-wrap h3 {
  font-size: 22px;
  color: var(--CFEP-blue-color);
  text-align: center;
}
.welcome-wrap p {
  font-size: 18px;
  color: var(--CFEP-Blue-color);
  text-align: center;
}
.welcome-wrap form {
  max-width: 510px;
  width: 100%;
  margin: 0 auto;
}
.welcome-wrap form input {
  font-size: 14px;
  background: var(--CFEP-Stone-color);
  padding: 10px;
}
.welcome-wrap form span {
  text-align: center;
  font-size: 14px;
  color: #626060;
}
.welcome-wrap form span a {
  color: var(--CFEP-blue-color);
}
.welcome-wrap form span a:hover {
  color: var(--CFEP-blue-color);
  text-decoration: underline;
}
.extra-box {
  max-width: 925px;
  width: 100%;
  background: var(--CFEP-Stone-color);
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  margin: 30px auto;
}
.extra-box span {
  font-size: 18px;
  color: #626060;
}
.extra-box span a {
  color: var(--CFEP-blue-color);
}
.extra-box span a:hover {
  color: var(--CFEP-blue-color);
  text-decoration: underline;
}
label a {
  color: var(--CFEP-blue-color);
}
/* ============= Completed survey style ========== */
.page-content .accordion-button:not(.collapsed) {
  background: var(--CFEP-Blue-color);
  color: var(--white-color);
}
.green-box {
  display: flex;
  justify-content: space-between;
  background-color: var(--CFEP-blue-color);
  margin: 12px 0;
  padding: 10px;
}
.green-box span {
  font-size: 18px;
  color: var(--white-color);
}
.footer-wrap {
  max-width: 925px;
  width: 100%;
  margin: 15px auto;
  text-align: right;
}
.footer-wrap-full {
  width: 100%;
  margin: 15px auto;
  text-align: right;
}
/* ============= Completed Survey Star ========== */
.survey-star {
  background: url(/assets/images/CFEP-Background-survey.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.survey-star h3 {
  font-size: 22px;
  color: var(--CFEP-blue-color);
}
.survey-star h4 {
  font-size: 18px;
  color: var(--CFEP-blue-color);
}
.survey-star span {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}
.survey-star ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 20px;
}
.survey-star ul li {
  position: relative;
  /* font-size: 13px; */
  line-height: 22px;
}
.survey-star ul li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: var(--CFEP-blue-color);
  top: 6px;
  left: -15px;
  border-radius: 100%;
}
.survey-star p {
  margin-top: 10px;
  /* font-size: 13px; */
}
.survey-star button.btn-deal img {
  position: relative;
  top: 0;
}
.survey-star .img-side {
  position: relative;
}
.survey-star .img-side img {
  float: right;
}
.survey-star .img-side .scan {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* ========== Questionnaire survey style start ============ */
.questionnaire-wrap {
  background: none;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.questionnaire-wrap .qus-wrap {
  display: flex;
  background: #f8f8f8;
}
.progress-bar-wrap {
  max-width: 1199px;
  width: 100%;
  margin: 8px auto 0;
  padding: 20px 0;
}
.progress-bar-wrap .progress {
  /* background: var(--CFEP-Grey-color); */
  background: #f8f8f8;
  overflow: unset;
  border-radius: 30px;
}
.progress-bar-wrap .progress-bar {
  background-color: #88bbe2;
  position: relative;
  overflow: unset;
  border-radius: 30px;
}
.progress-bar-wrap .progress-bar .step {
  position: absolute;
  content: "";
  width: 47px;
  height: 47px;
  line-height: 39px;
  border-radius: 100%;
  text-align: center;
  color: var(--white-color);
  background: #88bbe2;
  transform: translateX(-50%);
  top: -31px;
  left: 50%;
}
.questionnaire-wrap h2 {
  background: var(--CFEP-blue-color);
  color: #ffff;
  font-size: 18px;
  padding: 10px;
}
/*Question Row style */
.question-row {
  margin: 15px 0;
}
.question-row .no-side {
  background: #f0f0f0;
  padding: 20px;
  font-size: 18px;
  color: #073342;
  display: flex;
  justify-content: center;
  align-items: center;
}
.question-row .lavel-side {
  background: #f8f8f8;
  padding: 10px;
  width: 100%;
}
.question-row .lavel-side p {
  font-size: 14px;
  margin: 0 0 6px 6px;
  font-weight: 600;
}
.rateing-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.rateing-wrap .box {
  text-align: center;
  /* font-size: 13px; */
  margin: 0 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.rateing-wrap .box .highlight {
  background: #ced4d1;
  border-radius: 5px;
  text-align: center;
  padding: 10px 20px;
}
.rateing-wrap .box .highlight.active,
.rateing-wrap .box .highlight:hover {
  background: #b3d0f0;
  cursor: pointer;
}
.btn-nxtprv {
  display: flex;
  justify-content: end;
  margin-top: 25px;
  gap: 20px;
}
.btn-nxtprv .btn-nxt {
  background-color: var(--CFEP-Green-color);
  border: none;
  border-radius: 3px;
  padding: 10px 18px;
  font-size: 14px;
  color: var(--white-color);
}
.btn-nxtprv .btn-nxt:hover {
  background: var(--CFEP-Leaf-color);
  box-shadow: none;
}
.btn-nxtprv .btn-bck {
  background-color: #5dc8f5;
  border: none;
  border-radius: 3px;
  padding: 10px 18px;
  font-size: 14px;
  color: #fff;
  border: 1px solid transparent;
}
.btn-nxtprv .btn-bck:hover {
  border: 1px solid #c9c9c9;
  box-shadow: none;
}
.questionnaire-wrap .txt {
  background: #f0f0f0;
  padding: 15px;
}
.questionnaire-wrap .txt p {
  font-size: 16px;
  margin: 0;
}
.check-wrap {
  display: flex;
  gap: 20px;
  margin: 30px 0;
}
.check-wrap .box {
  background-color: #f8f8f8;
}
.check-wrap .box .title {
  font-size: 15px;
  color: #073342;
  background-color: #f0f0f0;
  padding: 10px;
}
.check-wrap .box ul {
  list-style: none;
  margin: 0;
  padding: 10px;
}
.check-wrap .box ul li {
  line-height: 20px;
  display: flex;
  align-items: center;
}
.check-wrap .box ul li input {
  margin-right: 10px;
}
.check-wrap .box ul li span {
  margin-left: 15px;
}
.check-wrap .box .qus-wrap {
  padding: 10px;
}
.check-wrap .box .qus {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  gap: 10px;
}
.check-wrap .box .qus .right input {
  float: right;
}
.check-wrap .box .qus .left p {
  margin: 0;
}
.check-wrap .box .qus .right input {
  margin-right: 10px;
  position: relative;
  top: 3px;
}
.submit-btn {
  background-color: var(--CFEP-Green-color);
  border: none;
  border-radius: 3px;
  padding: 12px 50px;
  font-size: 22px;
  color: var(--white-color);
}
.submit-btn:hover {
  background: var(--CFEP-Leaf-color);
  box-shadow: none;
}
.thankyou {
  text-align: center;
  margin-top: 50px;
}
.thankyou img.logo {
  width: 275px;
}
.thankyou span {
  font-size: 36px;
  color: var(--CFEP-blue-color);
}
.thankyou h4 {
  font-size: 22px;
  color: var(--CFEP-blue-color);
}
.thankyou .social {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.thankyou .social a {
  font-size: 14px;
  color: var(--CFEP-blue-color);
}
.return-bck {
  background-color: var(--CFEP-Green-color);
  border: none;
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 22px;
  color: var(--white-color);
  display: inline-block;
  margin-top: 30px;
}
.return-bck:hover {
  background: var(--CFEP-Leaf-color);
  box-shadow: none;
  color: var(--white-color);
}

:not(.collapsed) ::before {
  transform: rotate(-180deg);
}

/*----------------------------------------------------------------------
## Survey 2.0 style start ##
----------------------------------------------------------------------*/
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.ml10 {
  margin-left: 10px;
}
/* =====================  Survey header top =================== */
header.survey-hdr {
  text-align: center;
  border-top: 10px solid var(--CFEP-blue-color);
}
.body-title {
  background: var(--CFEP-blue-color);
  padding: 10px;
  margin-bottom: 20px;
}
.body-title h2 {
  font-size: 22px;
  color: var(--white-color);
  font-family: "frank_newregular";
  margin: 0;
}
.mt50 {
  margin-top: 50px;
}

/* ======================== survey-page-content style ================ */
.survey-page-content {
  background: url(/assets/images/survey-bg.png) top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 40px;
}

/* ======================= View Deal screen page style ========== */
.points {
  /* overflow-y: scroll; */
  padding: 15px;
  margin-top: 10px;
  /* height: 470px; */
}
.points::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* Track (the area behind the thumb) */
.points::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle (the draggable scrolling element) */
.points::-webkit-scrollbar-thumb {
  background: #35556d;
}
/* Handle on hover */
.points::-webkit-scrollbar-thumb:hover {
  background: #1f384c;
}
.points ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.points ul li {
  background: #f8f8f8;
  font-size: 14px;
  color: var(--CFEP-blue-color);
  margin: 3px 0;
  padding: 5px 5px 5px 18px;
  position: relative;
}
.points ul li:hover {
  background: none;
}
.points ul li::before {
  position: absolute;
  content: "";
  background: var(--CFEP-blue-color);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  left: 6px;
  top: 40%;
}
.survey-right {
  /* overflow-y: scroll; */
  padding: 15px;
  margin-top: 10px;
  /* height: 470px; */
}

.survey-right table th {
  /* font-size: 14px; */
  color: #35556d;
  background: #f8f8f8;
  font-family: "frank_newlight";
  padding: 4px 10px;
  vertical-align: middle;
}
.survey-right table tbody tr {
  padding: 3px 0;
}
.survey-right table td i {
  font-size: 7px;
  color: var(--CFEP-blue-color);
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.survey-right table td {
  color: #23a9df;
  padding: 2px 10px;
  background: none;
  border: none;
}
.survey-right table td span {
  color: var(--CFEP-blue-color);
}
.btn-survey {
  background-color: var(--CFEP-blue-color);
  font-size: 14px;
  border-radius: 3px;
  padding: 10px 18px;
  color: var(--white-color);
  border: none;
}
.btn-survey:hover {
  background-color: var(--CFEP-slate-color);
  border-color: var(--CFEP-blue-color);
  box-shadow: none;
  color: var(--white-color);
}
.survey-right::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* Track (the area behind the thumb) */
.survey-right::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle (the draggable scrolling element) */
.survey-right::-webkit-scrollbar-thumb {
  background: #35556d;
}
/* Handle on hover */
.survey-right::-webkit-scrollbar-thumb:hover {
  background: #1f384c;
}
/* ======================= Setup/Edit Survey Screen Page Style ========== */
.points ul li textarea {
  display: block;
  width: 98%;
  height: 73px;
}
.setup-left-form {
  padding: 20px 0;
  margin-top: 30px;
}
.parent-box {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}
.parent-box .left-box {
  width: 40%;
}
.parent-box .right-box {
  width: 60%;
}
.parent-box .left-box span {
  font-size: 14px;
  color: var(--CFEP-blue-color);
}
.parent-box .right-box span {
  font-size: 14px;
  color: var(--CFEP-blue-color);
}
.parent-box .right-box i.fa-circle {
  color: var(--CFEP-Green-color);
}
.form-check-input {
  border: 1px solid #a8a8a8;
  width: 20px;
  height: 20px;
}
.points table {
  margin: 30px 0 0 0;
  width: 100%;
}
.points table th {
  font-size: 14px;
  color: #35556d;
  font-family: "frank_newlight";
  padding: 4px 0;
}
.points table td {
  color: #35556d;
  padding: 2px 0;
  background: none;
  border: none;
}
.setup-left-form .form-floating {
  margin-top: 25px;
}
.mt10 {
  margin-top: 10px;
}
td span.sub-txt {
  width: 150px;
  float: left;
}
.survey-right {
  /* overflow-y: scroll; */
  padding: 15px;
  margin-top: 10px;
  /* height: 470px; */
}
.survey-right::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* Track (the area behind the thumb) */
.survey-right::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle (the draggable scrolling element) */
.survey-right::-webkit-scrollbar-thumb {
  background: #35556d;
}
/* Handle on hover */
.survey-right::-webkit-scrollbar-thumb:hover {
  background: #1f384c;
}
.survey-right h3 {
  font-weight: normal;
  font-size: 18px;
  font-family: "frank_newregular";
}

/* ======================= View survey screen Page Style ========== */
table.sub th {
  background: none;
  font-size: 13px;
}
table.sub td {
  font-size: 11px;
  color: #35556d;
}
a.view-res-btn {
  background: #5dc8f5;
  padding: 5px 12px;
  color: #fff;
  border-radius: 3px;
  border: none;
  white-space: nowrap;
}
a.view-res-btn:hover {
  background: #54bde9;
}
.survey-right table td span {
  color: #35556d;
  /* font-size: 11px; */
}
.survey-right table td a {
  /* font-size: 12px; */
  white-space: nowrap;
}
td.sub-v2-wrap {
  display: flex;
  gap: 8px;
}
table.sub-v2 {
  border: 1px solid #bebebe;
  background: none;
}
table.sub-v2 tr th {
  background: none;
}
table.sub-v2 tr td,
table.sub-v2 tr th {
  border: 1px solid #bebebe;
  text-align: center;
  /* font-size: 11px; */
}
table.sub-v2 .txt-wrap {
  display: flex;
  gap: 8px;
}
table.sub-v2 .txt-wrap .txt {
  font-size: 12px;
  color: var(--CFEP-blue-color);
}
table.sub-v2 .txt-wrap .txt span {
  display: block;
}

/* ============ survey-wrap div style ==================== */
.survey-wrap {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 15px;
}
.survey-wrap .check-wrap {
  display: flex;
  background: #dee5e1;
}
.survey-wrap .box-wrap {
  width: 100%;
  padding: 20px;
}
.survey-wrap .box-wrap .box {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 3px 0;
}
.survey-wrap .box-wrap .box label {
  /* font-size: 11px; */
  color: var(--CFEP-blue-color);
}
/* ============ View Responses (PRACTITIONER)  page style ==================== */
.data-contain {
  display: flex;
  background-color: #f8f8f8;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0;
}
.data-contain .left-data {
  /* font-size: 12px; */
  color: #35556d;
}
.data-contain .right-data {
  /* font-size: 12px; */
  color: #35556d;
}
.sub-v2-wrap {
  display: flex;
  gap: 8px;
}
.details-coll {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: flex-end;
  gap: 5px;
}
.details-coll span input {
  margin-left: 6px;
}
table.custom-table thead tr th {
  background: #f8f8f8;
}
table.custom-table tr th input {
  position: relative;
  top: -3px;
  left: 5px;
}
/* ============ 158 ISQ (View Response) page style ==================== */
.blue-btn-big {
  background-color: var(--CFEP-blue-color);
  font-size: 14px;
  border-radius: 3px;
  padding: 10px 18px;
  color: var(--white-color);
  border: none;
}
.blue-btn-big:hover {
  background-color: var(--CFEP-slate-color);
  border-color: var(--CFEP-blue-color);
  box-shadow: none;
  color: var(--white-color);
}
.custom-table-v2 tr td {
  border: none;
  vertical-align: top;
  /* font-size: 12px; */
}
.custom-table-v2 tr td a {
  color: #23a9df;
  text-decoration: underline;
}
.custom-table-v2 tr td a:hover {
  text-decoration: none;
}
.custom-table-v2 tr td textarea {
  max-width: 350px;
  width: 100%;
}
.preNxt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.preNxt .btn-comman {
  background: #5dc8f5;
  padding: 4px 10px;
  border-radius: 3px;
  border: none;
}
.preNxt .btn-comman a {
  color: #fff;
}
.preNxt .btn-comman:hover {
  background: #51b6e1;
  color: #fff;
}
.table > :not(caption) > * > * {
  background-color: transparent;
}
.notBtn {
  display: flex;
  align-items: self-start;
  gap: 20px;
}
.notBtn a:hover {
  color: #fff;
}

/* ============ View Responses (Company) screen page style =============== */
.data-contain .right-data span {
  margin: 0 5px;
}
.bg-light {
  background: #f8f8f8;
}

/* ============ View Colleagues/SMC modalpage style =============== */
h6.sub-title {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 8px;
  font-size: 18px;
}
span.span-sub-title {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 10px;
}
span.space-left {
  margin-left: 15px;
}
span.space-left img {
  width: 12px;
}
.body-title a {
  font-size: 16px;
}
.body-title a:hover {
  color: #5dc8f5;
}
/* ========= Practitioner CFET Dashboard wireframe page style ======== */
.prac-cfet h6.sub-title {
  text-align: center;
}
.p-tb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.box-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.box-wrap .box {
  background-color: #f8f8f8;
  border: 1px solid #d9d9d9;
  padding: 25px;
  max-width: 450px;
  width: 100%;
  min-height: 130px;
  text-align: center;
}
.box-wrap .box h3 {
  font-size: 16px;
  color: #35556d;
}
.address-box {
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 15px;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.address-box .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  gap: 10px;
}
.address-box .box span {
  font-size: 14px;
  font-weight: 600;
  color: #35556d;
  display: block;
}
.address-box .box p {
  margin: 0;
}
.address-box .box.border-right {
  border-right: 2px solid #35556d;
}
/* ========= Add Colleague/SMC screen page style ======== */
.title-light {
  background-color: #f8f8f8;
  font-size: 18px;
  color: #35556d;
  padding: 5px 10px;
}
ul.col-smc {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.col-smc li {
  padding: 10px 0;
}
ul.col-smc li img {
  margin-right: 8px;
}
ul.col-smc li span {
  font-size: 12px;
}
ul.col-smc li span a {
  color: #23a9df;
}
table.dark-table thead tr th {
  background: #35556d;
  color: #fff;
}
table.dark-table tbody tr.light-bg {
  background-color: #f8f8f8;
}

table.line-table thead tr {
  border-top: 1px solid #35556d;
  border-bottom: 1px solid #35556d;
}
table.line-table thead tr th {
  color: #35556d;
}
table.line-table tbody tr {
  box-shadow: none;
  border-bottom: none;
}
table.line-table tbody tr td {
  box-shadow: none;
  border-bottom: none;
}
table.line-table tbody a {
  color: #23a9df;
}
.custom-space {
  width: 100%;
  height: 50px;
}
/* ========= Merge Responses screen page style ======== */
.marge-box-wrap {
  background: #fafafa;
  border: 1px solid #cacaca;
  padding: 12px;
  display: flex;
  max-width: 850px;
  margin: 0 auto;
  justify-content: space-between;
  gap: 20px;
}
.marge-box-wrap .box {
  width: 100%;
}
.sky-blue button.blue-btn {
  background-color: #23a9df;
}
.sky-blue-btn {
  background-color: #23a9df !important;
}
.complete-msg {
  text-align: center;
  max-width: 600px;
  margin: 30px auto;
}
.complete-msg h4 {
  font-size: 22px;
  color: #35556d;
}
.complete-msg p {
  font-size: 14px;
}

/* print-wrap */

.print-title {
  display: flex;
  justify-content: space-between;
}
.print-title span {
  font-size: 16px;
  font-size: #35556d;
}
.print-wrap {
  overflow-y: scroll;
  padding: 15px;
  margin-top: 10px;
  height: 300px;
  background: #f8f8f8;
}
.print-list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c5c5c5;
  padding: 8px 0;
}
.print-list span {
  font-size: 11px;
}

/* ========= Review Comments scan page style ======== */
.review-wrap {
  background: #f6f6f6;
  padding: 15px;
  margin: 15px 0;
}
ul.review-details {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.review-details li p {
  font-size: 14px;
  margin: 0;
}
ul.review-details li p span {
  font-size: 16px;
}
.reviewSelected {
  background: #8cc342;
}
.custom-table-v2 .reviewSelected td,
.custom-table-v2 .reviewSelected td a {
  color: #fff;
}

/* ============  Benchmarking Stats screens page style ==================== */
.Progress-status {
  background-color: #f8f8fc;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}
.Progress-status span {
  font-size: 16px;
  color: #000;
}
.Progress-status p {
  margin: 0;
  color: #23a9df;
}
.border-bottom {
  border-bottom: 1px solid #d9d9d9;
}
.mb20 {
  margin-bottom: 20px;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mb0 {
  margin-bottom: 0;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
  margin-top: 30px !important;
}
legend.scheduler-border {
  font-size: 14px !important;
  background: #ececf6;
  padding: 0 0 0 10px;
  margin-top: -12px;
  max-width: 180px;
}
/* tabs-wrap div style */
.tabs-wrap .nav-tabs {
  background: #f8f8f8;
}
.tabs-wrap ul.nav .nav-item {
  padding: 0;
  font-size: 14px;
}
.tabs-wrap ul.nav .nav-item button.nav-link {
  color: #35556d;
}
.tabs-wrap .tab-content {
  background: #ffffff;
  padding: 20px;
}
ul.makeLive {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.makeLive li {
  border-bottom: 1px solid #b7b7b7;
  padding: 5px 0;
  font-size: 14px;
}
.compare-box {
  background: #f8f8f8;
  border: 1px solid #d2cdcd;
  padding: 10px;
}
.compare-box .top {
  display: flex;
  gap: 10px;
  align-items: center;
}
.compare-box .bottom {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.compare-box .top .form-select {
  width: 60%;
}
.compare-box .form-check {
  display: flex;
  gap: 5px;
  align-items: center;
}
.footer-txt span {
  width: 100%;
  display: block;
}
.footer-txt textarea {
  width: 100%;
  margin-top: 10px;
  padding: 12px;
}
.footer-txt .big-btn-wrap {
  justify-content: end;
}
/* ============  Benchmarking Generation page style ==================== */
.radioMark {
  display: flex;
  gap: 20px;
  align-items: center;
}
.radioMark .form-check {
  display: flex;
  gap: 5px;
  align-items: center;
}
.select-bench {
  display: flex;
  gap: 10px;
  align-items: center;
}
.select-bench select {
  font-size: 14px;
}
.ben-box {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
}
.ben-box span {
  width: 30%;
}
.ben-box div {
  width: 100%;
}
.ben-box select {
  font-size: 13px;
}
.data-txtarea textarea {
  width: 100%;
  padding: 12px;
}
.data-txtarea .big-btn-wrap {
  justify-content: end;
}
/* ============  Benchmarking Progress page style ==================== */
.select-box-wrap {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: end;
}
.select-box-wrap .select-box {
  margin-top: 10px;
  width: 50%;
}
.select-box-wrap .select-box label {
  margin-bottom: 10px;
  display: block;
}
.big-textarea textarea {
  width: 100%;
  padding: 12px;
}
.gen-rep h3 {
  font-size: 18px;
}
.gen-rep h4 {
  font-size: 16px;
  font-weight: 600;
}

@-moz-document url-prefix() {
  select option {
    font-family: "frank_newregular", Arial, sans-serif;
  }
}
