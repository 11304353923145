/************ Laptop Screen *************/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .thankyou .social {
    gap: 10px;
  }
  .thankyou img.logo {
    width: 205px;
  }
}

/************ Laptop & Tab *************/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  header img.NextView-logo {
    width: 150px;
  }
  header img.cfep-logo {
    width: 100px;
  }
  h2.title {
    font-size: 20px;
  }
  button.btn-adv {
    font-size: 14px;
  }
  .sorting-tabs ul li.nav-item span {
    font-size: 14px;
  }
  .survey-star .img-side img {
    width: 348px;
  }
  .survey-star .img-side .scan img {
    width: 148px;
  }
  .footer-wrap {
    text-align: center;
  }
  .check-wrap {
    flex-wrap: wrap;
  }
  .thankyou .social {
    gap: 10px;
    flex-direction: column;
  }
  .thankyou img.logo {
    width: 205px;
  }
  .thankyou span {
    font-size: 30px;
    line-height: 38px;
    margin-top: 13px;
  }
}
/************ Tab and Mobile *************/
@media (max-width: 840px) {
  .d-responsive {
    display: block !important;
  }
  .d-responsive .notBtn {
    justify-content: center;
    margin-top: 20px;
  }
  .select-box-wrap .select-box {
    width: 40%;
  }
}

/************ Tab and Mobile *************/
@media (max-width: 767px) {
  body,
  html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  .text-xs-center {
    text-align: center !important;
  }
  button.btn-adv {
    font-size: 14px;
  }
  h2.title {
    font-size: 18px;
  }
  .sorting-tabs ul li.nav-item span {
    font-size: 14px;
  }
  header {
    text-align: center;
  }
  header .NextView-logo {
    width: 100px;
  }
  header .cfep-logo {
    width: 100px;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .sorting-tabs ul li.nav-item {
    width: 100%;
  }
  .sorting-tabs ul li.nav-item span {
    font-size: 14px;
  }
  .sorting-tabs-data .table thead tr th {
    font-size: 12px;
  }
  .sorting-tabs-data .table thead tr th img {
    width: 14px;
  }
  .sorting-tabs-data .table tbody tr td {
    font-size: 12px;
  }
  button.btn-adv {
    font-size: 14px;
  }
  button.btn-adv img {
    width: 15px;
  }
  .welcome-wrap,
  .extra-box {
    max-width: 575px;
  }
  .survey-star .img-side img {
    width: 298px;
    margin: 0 auto;
    float: none;
  }
  .survey-star .img-side .scan img {
    width: 148px;
  }
  .footer-wrap {
    text-align: center;
  }
  .footer-wrap-full {
    text-align: center;
  }
  .check-wrap {
    flex-wrap: wrap;
  }
  .submit-btn {
    font-size: 16px;
    padding: 8px 30px;
  }
  .thankyou {
    margin-top: 10px;
  }
  .survey-star .img-side {
    margin-top: 40px;
    text-align: center;
  }
  .survey-star .img-side .scan img {
    width: 100px;
  }
  .thankyou img.logo {
    width: 205px;
  }
  .thankyou span {
    font-size: 24px;
  }
  .thankyou h4 {
    font-size: 18px;
  }
  .return-bck {
    font-size: 16px;
    padding: 7px 20px;
  }
  .return-bck img {
    width: 18px;
  }
  .survey-star .img-side .scan {
    right: 50%;
  }
  /* Survey 2.0 responsive style */
  .body-title h2 {
    font-size: 18px;
  }
  .points ul li {
    font-size: 11px;
  }
  .btn-survey {
    font-size: 12px;
    padding: 8px;
  }
  .btn-survey img {
    width: 18px;
  }
  .survey-right {
    height: 320px;
  }
  .big-btn-wrap .gray-btn,
  .big-btn-wrap .blue-btn {
    font-size: 11px;
  }
  .survey-right h3 {
    font-size: 14px;
  }
  .address-box {
    flex-direction: column;
  }
  .address-box .box {
    flex-direction: column;
    text-align: center;
  }
  .address-box .box.border-right {
    border-right: none;
    border-bottom: 1px solid #35556d;
    padding: 10px 0;
  }
  .address-box .box img {
    width: 32px;
  }
  .sub-v2-wrap {
    flex-direction: column;
  }
  .select-box-wrap {
    display: block;
  }
  .select-box-wrap .select-box {
    width: 100%;
    margin-bottom: 15px;
  }
  .points {
    height: auto;
  }
  .gen-rep h3 {
    font-size: 15px;
  }
}
/************ All Mobile and small size screen *************/
@media (max-width: 575px) {
  .nav-phn img {
    width: 30px;
  }
  .sideBar {
    display: none;
  }
  .content-wrap {
    margin-left: 0;
  }
  .nav-phn {
    display: block;
    position: relative;
    z-index: 999;
  }
  .nav-phn img.phn-toggle {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 20px;
  }
  .extra-box span {
    font-size: 15px;
  }
  .footer-wrap-full {
    text-align: center;
    margin-top: 30px;
  }
  .tab-content > .tab-pane {
    margin-bottom: 20px;
  }
  .survey-star .img-side img {
    width: 248px;
  }
  .check-wrap .box .qus {
    flex-direction: column;
  }
  .submit-btn {
    font-size: 16px;
    padding: 8px 30px;
  }
  .thankyou .social {
    flex-direction: column;
  }
  .thankyou img.logo {
    width: 146px;
    margin-bottom: 11px;
  }
  .question-row .no-side {
    font-size: 16px;
    padding: 6px 20px;
  }
  .blue-btn-big {
    font-size: 12px;
  }
  .body-title h2 {
    font-size: 14px;
  }
  .body-title a {
    font-size: 13px;
  }
  .p-tb-20 h6 {
    font-size: 14px;
  }
  .title-light {
    font-size: 16px;
  }
  .light-md-btn,
  .btn-wrap .cancel-btn,
  .btn-wrap .save-btn {
    place-self: flex-start;
    margin: 5px;
  }
}
/************ All Mobile and small size screen *************/
@media (max-width: 479px) {
  .page-content ul.nav .nav-item {
    width: 100%;
  }
  .adv-wrap .form-select,
  .adv-wrap input {
    font-size: 13px;
  }
  .survey-star h3 {
    font-size: 18px;
  }
  .survey-star h4 {
    font-size: 16px;
  }
  .questionnaire-wrap h2 {
    font-size: 15px;
  }
  .btn-nxtprv {
    justify-content: center;
  }
  .btn-nxtprv a {
    font-size: 13px;
    padding: 7px 11px;
  }
  .btn-nxtprv a img {
    width: 15px;
  }
  .big-btn-wrap {
    flex-direction: column;
  }
  .d-responsive .notBtn {
    display: block;
    max-width: 300px;
    margin: 30px auto 0;
    text-align: center;
  }
  .d-responsive .notBtn .blue-btn-big {
    display: block;
    margin: 0 auto;
  }
  .complete-msg img {
    width: 28px;
  }
  .complete-msg h4 {
    font-size: 18px;
  }
}
