.spin-infinite-and-scale {
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: scale(0.75) rotate(0deg);
  }
  100% {
    transform: scale(0.75) rotate(-359deg);
  }
}
